





























































import { GenerateWallet } from '@/helpers/Home/Redeem'
import Vue from 'vue'
export default Vue.extend({
  name: 'Videos',
  data: () => ({
    src: ''
  }),
  components: {
  },
  methods: {
  },
  async mounted () {
    this.src = await GenerateWallet('12345', false) as string
  }
})
